import React, { useEffect, useState } from 'react';

import { PlusOutlined, MoreOutlined } from '@ant-design/icons';
import { Divider, Drawer, Form, Input, Spin } from 'antd';
const { TextArea } = Input;
import { useSelector } from 'react-redux';
import { Delete, Edit } from '../../utils/Images';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import moment from 'moment';
import CommonModal from '../modals/CommonModal';
import DeleteModal from '../modals/DeleteModal';
import ThemeButton from '../common/ThemeButton';

const AddNotesTab = () => {
    const [editNotesModal, setEditNotesModal] = useState(false);
    const [openANotesModal, setOpenNotesModal] = useState(false);
    const [deleteNotesModal, setDeleteNotesModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [detailsNoteData, setDetailsNoteData] = useState([]);
    const [selectedData, setSelectedData] = useState({});

    const [noteToDelete, setNoteToDelete] = useState(null);

    const timestamp = selectedData.updatedAt || selectedData.createdAt;
    const formattedDate = moment(timestamp).format('YYYY-MM-DD');
    const formattedTime = moment(timestamp).format('hh:mm:ss');

    const { id } = useParams();

    const imageBaseUrl = useSelector(state => state.config.image_base_url);

    const email = localStorage.getItem('email');
    const clientData = useSelector(state => state.account.clientData);
    const paymentProvider = localStorage.getItem('paymentProviderId');

    const [form] = Form.useForm();

    const handleClose = () => {
        setOpenNotesModal(false);
        setSelectedData({});
    };

    const setOpenAddNotesModal = () => {
        form.resetFields();
        setEditNotesModal(false);
        setOpenNotesModal(true);
    };

    const handleEdit = note => {
        form.setFieldsValue({
            note: note.data,
        });
        setSelectedData(note);
        setOpenNotesModal(true);
        setEditNotesModal(true);
        setDetailsModal(false);
    };

    const handleDeleteNotesData = userData => {
        setDeleteNotesModal(true);
        setSelectedData(userData);
    };

    const handleDeleteNotesDataMView = userData => {
        setSelectedData(userData);
        setDetailsModal(true);
    };

    const getNotes = async () => {
        try {
            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
            };

            const response = await apiRequest('/get-notes', 'POST', body);

            if (response.success) {
                setDetailsNoteData(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch notes:', error.response?.data || error.message);
        }
    };
    useEffect(() => {
        getNotes();
    }, [id]);

    const handleAddNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                data: formValues.note,
                createdBy: email,
            };

            const response = await apiRequest('/add-note', 'POST', body);

            if (response.success) {
                setDetailsNoteData(prevData => [...prevData, response.data.result]);
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to add note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEditNotes = async () => {
        try {
            setLoading(true);
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                data: formValues.note,
                createdBy: email,
                _id: selectedData._id,
            };

            const response = await apiRequest('/update-note', 'POST', body);

            if (response.success) {
                setDetailsNoteData(prevNotes =>
                    prevNotes.map(note =>
                        note._id === selectedData._id ? response.data.result : note,
                    ),
                );
            } else {
                message.error(response.error);
            }

            setOpenNotesModal(false);
        } catch (error) {
            console.error('Failed to edit note:', error.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteNotes = async noteId => {
        try {
            const formValues = await form.validateFields();

            const body = {
                email: email,
                paymentProvider: paymentProvider,
                clientId: id,
                _id: noteId,
            };

            const response = await apiRequest('/delete-note', 'DELETE', body);

            if (response.success) {
                setDetailsNoteData(prevNotes => prevNotes.filter(note => note._id !== noteId));
            } else {
                message.error(response.error);
            }

            setDetailsModal(false);
            setDeleteNotesModal(false);
        } catch (error) {
            console.error('Failed to delete note:', error.response?.data || error.message);
        }
    };

    return (
        <div className="bg-[#ffffff] lg:p-0 p-[12px]">
            <div className="lg:p-0 lg:mt-0 bg-white">
                <div className="py-6 pb-2 pt-4 lg:pt-8 lg:px-0 flex items-center justify-between">
                    <h1 className="text-[#18181B] font-inter text-lg font-semibold leading-[28px]">
                        Notes
                    </h1>
                    <div className="font-medium text-sm">
                        <ThemeButton
                            type="primary"
                            style="lg:inline-flex hidden"
                            icon={<PlusOutlined />}
                            dataE2e="add-notes"
                            text="Add Notes"
                            action={setOpenAddNotesModal}
                        />
                        <ThemeButton
                            style="lg:hidden"
                            type="primary"
                            icon={<PlusOutlined />}
                            dataE2e="add-new"
                            text="Add new"
                            action={setOpenAddNotesModal}
                        />
                    </div>
                </div>
            </div>

            <div>
                <CommonModal
                    title={
                        <p className="text-[#18181B] font-inter text-2xl font-semibold leading-[32px]">
                            {editNotesModal ? 'Edit Note' : 'Add Note'}
                        </p>
                    }
                    loading={loading}
                    onFinish={editNotesModal ? handleEditNotes : handleAddNotes}
                    form={form}
                    open={openANotesModal}
                    handleClose={handleClose}
                    className={'common-mobile-view noteModalTabview'}
                    footerText={editNotesModal ? 'Save' : 'Apply'}>
                    <Form
                        layout="vertical"
                        className="mt-10 modal-form !md:h-[458px] "
                        form={form}
                        colon={false}
                        requiredMark={false}>
                        <div className="!md:h-[458px] ">
                            <Form.Item
                                name="note"
                                label={
                                    <p className="self-stretch text-[#26272B] font-inter text-base font-normal leading-6 ">
                                        Note:
                                    </p>
                                }
                                rules={[{ required: true, message: 'Please enter your note.' }]}>
                                <TextArea
                                    rows={6}
                                    placeholder="Add your note..."
                                    className=" resize-none  !h-[200px] text-base rounded-[8px] p-2 float-end bg-[#F4F4F5] border-none hover:bg-[#F4F4F5] py-[12px] px-[16px]"></TextArea>
                            </Form.Item>
                        </div>
                    </Form>
                </CommonModal>
            </div>

            <Divider className="hidden lg:block my-2 " />

            {detailsNoteData.map((userData, index) => {
                const timestamp = userData.updatedAt || userData.createdAt;
                const formattedDate = moment(timestamp).format('YYYY-MM-DD');
                const formattedTime = moment(timestamp).format('hh:mm:ss');
                return (
                    <div key={userData._id}>
                        <div className="note-item flex justify-between items-center mt-4 rounded-[18px] ">
                            <div className="flex flex-col flex-grow">
                                <p
                                    className="lg:text-base text-[#18181B]
                            overflow-hidden  text-ellipsis  text-sm sm:font-normal sm:leading-[20px] sm:text-base">
                                    {userData.data}
                                </p>
                                <p
                                    className=" text-[#70707B]
                            overflow-hidden text-ellipsis font-inter text-[13px] italic font-normal leading-[20px]">
                                    {`Added by ${clientData.name} - ${formattedDate}    ${formattedTime}`}
                                </p>
                            </div>

                            <div className="flex gap-2">
                                <div className="hidden  md:block">
                                    <button
                                        className="border-0 rounded-full bg-[rgb(255,255,255)] p-[10px]"
                                        onClick={() => handleEdit(userData)}>
                                        <img src={`${imageBaseUrl}${Edit}`} alt="edit" />
                                    </button>

                                    <button
                                        className="border-0 rounded-full bg-[#ffffff] p-[10px]"
                                        onClick={() => handleDeleteNotesData(userData)}>
                                        <img
                                            src={`${imageBaseUrl}${Delete}`}
                                            alt="Delete"
                                            width={20}
                                            height={20}
                                        />
                                    </button>
                                </div>

                                <div className="flex lg:hidden md:hidden ">
                                    <MoreOutlined
                                        className="!text-lg inline "
                                        onClick={() => handleDeleteNotesDataMView(userData)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider className="my-2 " />
                    </div>
                );
            })}

            <Drawer
                title={false}
                placement="bottom"
                closable={false}
                onClose={() => setDetailsModal(false)}
                height="auto"
                open={detailsModal}
                key="bottom"
                className="w-[375px] lg:hidden rounded-xl border-t border-gray-100 bg-white ">
                <div className="">
                    <div className="flex justify-between items-center mb-9">
                        <h1 className="text-[#18181B] font-inter text-lg font-semibold leading-[28px] break-words overflow-wrap break-word whitespace-normal max-w-full">
                            {selectedData.data}
                        </h1>
                        <p
                            className="text-lg font-semibold cursor-pointer"
                            onClick={() => setDetailsModal(false)}>
                            X
                        </p>
                    </div>
                    <div className="">
                        <p
                            className="text-[#51525C] font-inter text-base italic font-normal leading-6
                            ">
                            {`Added by ${clientData.name} - ${formattedDate}    ${formattedTime}`}
                        </p>
                    </div>

                    <div className="mt-9 flex justify-between">
                        <div className="flex flex-col items-center gap-1.5 w-[166.5px] h-[68px]">
                            <button
                                className="border-0 rounded-full bg-[#F4F4F5] p-[10px]"
                                onClick={() => handleEdit(selectedData)}>
                                <img
                                    src={`${imageBaseUrl}${Edit}`}
                                    alt="edit"
                                    width={24}
                                    height={24}
                                />
                            </button>
                            <p className="text-[#18181B] text-center font-inter text-xs font-normal leading-[18px] mt-2">
                                Edit
                            </p>
                        </div>

                        <div className="flex flex-col items-center gap-1.5 w-[166.5px] h-[68px]">
                            <button
                                className="border-0 rounded-full bg-[#ffffff] p-[10px]"
                                onClick={() => {
                                    setDeleteNotesModal(true);
                                    setNoteToDelete(clientData.clientId);
                                }}>
                                <img
                                    src={`${imageBaseUrl}${Delete}`}
                                    alt="Delete"
                                    width={24}
                                    height={24}
                                    className="svg-red"
                                />
                            </button>
                            <p className="text-[#18181B] text-center font-inter text-xs font-normal leading-[18px] mt-2">
                                Remove
                            </p>
                        </div>
                    </div>
                </div>
            </Drawer>

            <DeleteModal
                open={deleteNotesModal}
                title={'Delete note'}
                content={'Are you sure you want to delete the note?'}
                toDeleteName={selectedData.data}
                handleClose={() => setDeleteNotesModal(false)}
                handleDelete={() => handleDeleteNotes(selectedData._id)}
                toDelete={noteToDelete}></DeleteModal>
        </div>
    );
};

export default AddNotesTab;
