import WithdrawalAndTPPCommonModal from './WithdrawalAndTPPCommonModal';
import { message, Space, Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import WithdrawalAndTPPForm from './WithdrawalAndTPPForm';
import SavedBeneficiaryAccounts from './SavedBeneficiaryAccounts';
import WithdrawalAndTPPConfirmationModal from './WithdrawalAndTPPConfirmation';
import { ArrowLeftOutlined } from '@ant-design/icons';
import apiRequest from '../../utils/api';
import DeleteBankAccount from './DeleteBankAccount';
import TransactionResultModal from './TransactionResultModal';
import { getCurrencySymbol, toFixedTrunc } from '../../utils/common';
import { fiatCurrencies } from '../../utils/constant';
const { TabPane } = Tabs;

const WithdrawalAndTPPFiatModal = ({
    open,
    handleClose,
    balancesData,
    selectedCurrency,
    setSelectedCurrency,
    beneficiaryList,
    getBeneficiaryList,
    onWithdrawalSubmit,
    initialTitle,
    confirmTitle,
    transactionType,
    successProcess,
}) => {
    const dispatch = useDispatch();
    const paymentProvider = localStorage.getItem('paymentProviderId');
    const imgBaseUrl = useSelector(state => state.config.image_base_url);
    const [form] = Form.useForm();
    const clientData = useSelector(state => state.account.clientData);
    const [formValue, setFormValue] = useState({});
    const [fileList, setFileList] = useState([]);
    const [title, setTitle] = useState(initialTitle);
    const [isEditing, setIsEditing] = useState(false);
    const [isSavedAccountTab, setIsSavedAccountTab] = useState(false);
    const [isNewAccountTab, setIsNewAccountTab] = useState(true);
    const [activeKey, setActiveKey] = useState('1');
    const [isSavedAccount, setIsSavedAccount] = useState(false);
    const [isAccountEditing, setIsAccountEditing] = useState(false);
    const [isWithdrawToSaveAccount, setIsWithdrawToSaveAccount] = useState(false);
    const [attachmentsUrl, setAttachmentsUrl] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState('');
    const email = localStorage.getItem('email');
    const [isLoading, setIsLoading] = useState(false);
    const [isSelectedCurrency, setIsSelectedCurrency] = useState();

    useEffect(() => {
        if (selectedCurrency) {
            if (title === initialTitle) {
                setIsSelectedCurrency({ ...selectedCurrency });
                form.setFieldsValue({
                    currency: selectedCurrency?.currencyShortName,
                });
            }
        } else {
            // setSelectedCurrency(fiatCurrencies[0]);
            form.setFieldsValue({
                currency: balancesData[0]?.currencyShortName,
            });
        }
    }, [open, balancesData]);

    const onSubmit = async value => {
        try {
            await form.validateFields();
            setFormValue(prevState => ({
                ...prevState,
                ...form.getFieldsValue(),
            }));
            setTitle(confirmTitle);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const toggleSavedAccount = check => {
        setIsSavedAccount(check);
    };

    const openDeleteAccountModal = () => {
        setTitle('Delete Account');
    };

    const cancelDeleteAccount = () => {
        setTitle('Edit Accounts');
    };

    const resetForm = () => {
        form.resetFields();
        setFormValue({});
        setFileList([]);
    };

    const onClose = () => {
        resetForm();
        handleClose();
        setTitle(initialTitle);
        setActiveKey('1');
        setIsWithdrawToSaveAccount(false);
        setIsSuccess(false);
        setError('');
    };

    useEffect(() => {
        if (activeKey === '1') {
            setIsNewAccountTab(true);
            setIsSavedAccountTab(false);
        } else {
            setIsNewAccountTab(false);
            setIsSavedAccountTab(true);
        }
    }, [activeKey]);

    useEffect(() => {
        if (fileList.length > 0 && fileList.length === attachmentsUrl.length) {
            createTransactionCall();
        }
    }, [attachmentsUrl]);

    const onCurrencyChange = currency => {
        const selectedCurrency = balancesData.find(data => data.currencyShortName === currency);
        setIsSelectedCurrency(selectedCurrency);
    };

    const onReturnBack = value => {
        setTitle(value);
        setIsAccountEditing(false);
        setIsWithdrawToSaveAccount(false);
    };

    const returnBackToSavedAccountsPage = () => {
        onReturnBack(initialTitle);
        setActiveKey('2');
    };

    const setFormValueFromSavedAccount = account => {
        const formData = {
            beneficiaryId: account._id,
            beneficiaryName: account.beneficiaryName,
            beneficiaryAddress: account.beneficiaryAddress,
            bankName: account.bankName,
            bankAddress: account.bankAddress,
            accountNumber: account.beneficiaryAccountNumber,
            sortCode: account.sortCode,
            iban: account.iban,
            swift: account.swift,
            additionalInfo: account.additionalBankDetails,
            reference: account.reference,
            accountNickName: account.accountNickName,
            amount: '',
        };

        form.setFieldsValue(formData);

        setFormValue(prevState => ({
            ...prevState,
            ...formData,
        }));
    };

    const withdrawToSaveAccount = account => {
        resetForm();
        setTitle(initialTitle);
        setIsWithdrawToSaveAccount(true);
        setFormValueFromSavedAccount(account);
    };

    const openEditAccountModal = account => {
        setTitle('Edit Accounts');
        setIsAccountEditing(true);
        setFormValueFromSavedAccount(account);
    };

    const deleteAttachment = uid => {
        const updatedList = fileList.filter(file => file.uid !== uid);
        setFileList([...updatedList]);
    };

    const getSignedUrl = async (file, transactionImage, fileCount) => {
        const payload = {
            imgcount: `file${fileCount}`,
            imageType: 'clientPayout',
            transactionImage: transactionImage ? transactionImage : null,
            email,
        };
        const index = parseInt(fileCount);
        try {
            const response = await apiRequest('/get-client-presigned-url', 'POST', payload);
            if (response.success) {
                const signedUrl = response.data.data[0];
                await fetch(signedUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'Content-Type': 'application/*',
                        'cache-control': 'public, max-age=0',
                    },
                });
                const imgUrl = `${imgBaseUrl}/client-payout/file${index}/${transactionImage}/documents`;
                setAttachmentsUrl(x => [...x, imgUrl]);
            } else {
                setError(response?.error);
            }
        } catch (e) {
            setError(e.message);
        }
    };

    const saveBeneficiaryAccount = async () => {
        const request = {
            client: clientData._id,
            accountNickName: formValue.accountNickName,
            beneficiaryName: formValue.beneficiaryName,
            beneficiaryAccountNumber: formValue.accountNumber,
            beneficiaryAddress: formValue.beneficiaryAddress,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            iban: formValue.iban,
            swift: formValue.swift,
            additionalBankDetails: formValue.additionalInfo,
            reference: formValue.reference,
            sortCode: formValue.sortCode,
            email,
            currency: selectedCurrency.currencyShortName,
        };
        const response = await apiRequest('/save-client-beneficiary', 'POST', request);
        if (response.success) {
            await getBeneficiaryList(dispatch, email, clientData?.clientEmail);
        } else {
            setError(response?.error);
        }
    };

    const updateBeneficiaryAccount = async () => {
        const updatedForm = form.getFieldsValue();
        const request = {
            client: clientData._id,
            beneficiaryId: formValue.beneficiaryId,
            accountNickName: updatedForm.accountNickName,
            beneficiaryName: updatedForm.beneficiaryName,
            beneficiaryAccountNumber: updatedForm.accountNumber,
            beneficiaryAddress: updatedForm.beneficiaryAddress,
            bankName: updatedForm.bankName,
            bankAddress: updatedForm.bankAddress,
            iban: updatedForm.iban,
            swift: updatedForm.swift,
            additionalBankDetails: updatedForm.additionalInfo,
            reference: updatedForm.reference,
            sortCode: updatedForm.sortCode,
            currency: isSelectedCurrency.currencyShortName,
            email,
        };
        const response = await apiRequest('/update-client-beneficiary', 'POST', request);
        if (response.success) {
            message.success('Beneficiary account updated successfully');
            await getBeneficiaryList(dispatch, email, clientData?.clientEmail);
            setActiveKey('2');
            setTitle(initialTitle);
            setIsAccountEditing(false);
            resetForm();
        }
    };

    const deleteBeneficiaryAccount = async () => {
        setIsLoading(true);
        const requestBody = {
            beneficiaryId: formValue.beneficiaryId,
            email,
        };
        const response = await apiRequest('/delete-client-beneficiary', 'POST', requestBody);
        if (response.success) {
            await getBeneficiaryList(dispatch, email, clientData?.clientEmail);
            setActiveKey('2');
            setTitle(initialTitle);
            setIsAccountEditing(false);
            setIsNewAccountTab(true);
            setIsWithdrawToSaveAccount(false);
            setIsLoading(false);
            resetForm();
        } else {
            setIsLoading(false);
            message.error('Failed to delete beneficiary account');
            setTitle('Edit Accounts');
        }
    };

    const createTransactionCall = async () => {
        setIsLoading(true);
        const transactionEmail = clientData.clientEmail;
        const data = {
            beneficiaryName: formValue.beneficiaryName,
            beneficiaryAddress: formValue.beneficiaryAddress,
            bankName: formValue.bankName,
            bankAddress: formValue.bankAddress,
            beneficiaryAccountNumber: formValue.accountNumber,
            sortCode: formValue.sortCode,
            swift: formValue.swift,
            iban: formValue.iban,
            additionalBankDetails: formValue.additionalInfo,
            amount: formValue.amount,
            reference: formValue.reference,
            currency: isSelectedCurrency.currencyShortName,
        };
        const requestBody = {
            currencyType: 'fiat',
            type: transactionType,
            transactionDetails:
                attachmentsUrl.length > 0 ? { ...data, attachment: attachmentsUrl } : data,
            client: clientData.clientId,
            currency: isSelectedCurrency.currencyShortName,
            transactionEmail,
            paymentProvider,
            balance: {
                balanceAmount: formValue.amount,
            },
            email,
        };
        const response = await onWithdrawalSubmit(requestBody);
        if (response.success) {
            if (isSavedAccount) {
                await saveBeneficiaryAccount();
            }
            setIsLoading(false);
            setIsSuccess(true);
            setTitle('');
        } else {
            setIsLoading(false);
            setError(response?.error);
            setTitle('');
        }
    };

    const submitWithdrawal = async () => {
        try {
            setIsLoading(true);
            if (fileList.length > 0) {
                await Promise.all(
                    fileList.map(async (data, index) => {
                        const transactionImage = `transactionImage${Math.floor(100000 + Math.random() * 900000)}`;
                        await getSignedUrl(data.originFileObj, transactionImage, index);
                    }),
                );
            } else {
                await createTransactionCall();
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setError(e.message);
            setTitle('');
        }
    };

    return (
        <React.Fragment>
            {title === initialTitle && !isWithdrawToSaveAccount && (
                <WithdrawalAndTPPCommonModal
                    title={title}
                    open={open}
                    footerText="Continue"
                    isSavedAccountTab={isSavedAccountTab}
                    isNewAccountTab={isNewAccountTab}
                    onSubmit={onSubmit}
                    formValue={formValue}
                    handleClose={onClose}>
                    {beneficiaryList?.length > 0 && !isEditing && (
                        <Tabs
                            defaultActiveKey={activeKey}
                            className="tabs-custom my-2 "
                            type="card"
                            centered
                            onChange={setActiveKey}>
                            <TabPane tab="New Account" key="1">
                                <WithdrawalAndTPPForm
                                    formValue={formValue}
                                    form={form}
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    balancesData={balancesData}
                                    selectedCurrency={isSelectedCurrency}
                                    onCurrencyChange={onCurrencyChange}
                                    isSavedAccount={isSavedAccount}
                                    toggleSavedAccount={toggleSavedAccount}
                                    deleteAttachment={deleteAttachment}
                                />
                            </TabPane>
                            <TabPane tab="Saved Accounts" key="2">
                                <SavedBeneficiaryAccounts
                                    savedBeneficiaryAccounts={beneficiaryList}
                                    openEditAccountModal={openEditAccountModal}
                                    withdrawToSaveAccount={withdrawToSaveAccount}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                    {beneficiaryList?.length === 0 && !isEditing && title === initialTitle && (
                        <WithdrawalAndTPPForm
                            form={form}
                            formValue={formValue}
                            fileList={fileList}
                            setFileList={setFileList}
                            balancesData={balancesData}
                            selectedCurrency={isSelectedCurrency}
                            onCurrencyChange={onCurrencyChange}
                            isSavedAccount={isSavedAccount}
                            toggleSavedAccount={toggleSavedAccount}
                            deleteAttachment={deleteAttachment}
                        />
                    )}
                </WithdrawalAndTPPCommonModal>
            )}
            {title === confirmTitle && (
                <WithdrawalAndTPPConfirmationModal
                    open={open}
                    selectedCurrency={isSelectedCurrency}
                    formData={formValue}
                    attachments={fileList}
                    onBack={isWithdrawToSaveAccount ? returnBackToSavedAccountsPage : onReturnBack}
                    submitWithdrawal={submitWithdrawal}
                    initialTitle={initialTitle}
                    confirmTitle={confirmTitle}
                    isLoading={isLoading}
                    handleClose={onClose}
                />
            )}
            {title === 'Edit Accounts' && (
                <WithdrawalAndTPPCommonModal
                    title={
                        <Space>
                            <Button
                                type="text"
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    resetForm();
                                    onReturnBack('Withdrawal');
                                }}
                                className="mr-2 -ml-2"
                            />
                            <p className="text-2xl font-semibold relative">{title}</p>
                        </Space>
                    }
                    open={open}
                    footerText="Apply"
                    onSubmit={onSubmit}
                    isEditing={true}
                    updateBeneficiaryAccount={updateBeneficiaryAccount}
                    openDeleteAccountModal={openDeleteAccountModal}
                    handleClose={onClose}>
                    <WithdrawalAndTPPForm
                        form={form}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        fileList={fileList}
                        setFileList={setFileList}
                        balancesData={balancesData}
                        selectedCurrency={isSelectedCurrency}
                        onCurrencyChange={onCurrencyChange}
                        isAccountEditing={isAccountEditing}
                        deleteAttachment={deleteAttachment}
                    />
                </WithdrawalAndTPPCommonModal>
            )}
            {title === initialTitle && isWithdrawToSaveAccount && (
                <WithdrawalAndTPPCommonModal
                    title={
                        <Space>
                            <Button
                                type="text"
                                icon={<ArrowLeftOutlined />}
                                onClick={() => {
                                    resetForm();
                                    onReturnBack(initialTitle);
                                }}
                                className="mr-2 -ml-2"
                            />
                            <p className="text-2xl font-semibold relative">{title}</p>
                        </Space>
                    }
                    open={open}
                    footerText="Continue"
                    isWithdrawToSaveAccount={isWithdrawToSaveAccount}
                    onSubmit={onSubmit}
                    handleClose={onClose}>
                    <WithdrawalAndTPPForm
                        form={form}
                        formValue={formValue}
                        setFormValue={setFormValue}
                        fileList={fileList}
                        setFileList={setFileList}
                        balancesData={balancesData}
                        selectedCurrency={isSelectedCurrency}
                        onCurrencyChange={onCurrencyChange}
                        isAccountEditing={isAccountEditing}
                        isWithdrawToSaveAccount
                        deleteAttachment={deleteAttachment}
                    />
                </WithdrawalAndTPPCommonModal>
            )}
            {title === 'Delete Account' && (
                <DeleteBankAccount
                    open={open}
                    cancelDeleteAccount={cancelDeleteAccount}
                    deleteBeneficiaryAccount={deleteBeneficiaryAccount}
                    isLoading={isLoading}
                />
            )}
            {(isSuccess || error) && (
                <TransactionResultModal
                    type={error ? error : 'success'}
                    open={open}
                    handleClick={onClose}
                    title={error ? 'Error!' : 'Successful!'}
                    message={error ? error : `Has been ${successProcess} successfully!`}>
                    <div className={error ? 'hidden' : 'block'}>
                        {getCurrencySymbol(isSelectedCurrency?.currencyShortName)}{' '}
                        {`${toFixedTrunc(formValue.amount)} ${isSelectedCurrency?.currencyShortName}`}
                    </div>
                </TransactionResultModal>
            )}
        </React.Fragment>
    );
};

export default WithdrawalAndTPPFiatModal;
