import React, { useEffect, useState } from 'react';
import { Freeze, BalanceHistory, DefrostIcon } from '../../utils/Images';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Table } from 'antd';
import Loading from '../common/Loading';
import ConfirmBalanceChangeModal from '../modals/ConfirmBalanceChangeModal';
import ChangeBalanceForm from '../modals/ChangeBalanceForm';
import ThemeButton from '../common/ThemeButton';
import { CaretUpOutlined, CaretDownOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { getBalancesData, getCryptoBalances, useWindowWidth } from '../../utils/common';
import { getCryptoNameBySymbol } from '../../utils/common';
import { toFixedTrunc } from '../../utils/common';
import { useParams } from 'react-router-dom';
import FreezeConfirmationModal from '../modals/FreezeConfirmationModal';
import { currencyTypes } from '../../utils/constant';
import apiRequest from '../../utils/api';

const CryptoBalancesTab = () => {
    const [form] = Form.useForm();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const cryptoBalances = useSelector(state => state.account.cryptoBalancesData);
    const clientData = useSelector(state => state.account.clientData);
    const email = localStorage.getItem('email');
    const paymentProvider = localStorage.getItem('paymentProviderId');

    const [loading, setLoading] = useState(false);
    const [balanceLoading, setBalanceLoading] = useState(false);
    const [selectedBalance, setSelectedBalance] = useState({});
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const windowWidth = useWindowWidth();
    const [columns, setColumns] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [isFrozen, setIsFrozen] = useState(false);
    const [unfreezeBalances, setUnfreezeBalances] = useState([]);
    const [freezeModalOpen, setFreezeModalOpen] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const filteredBalances = cryptoBalances?.filter(balance => !balance.isFreeze);
        setUnfreezeBalances(filteredBalances);
        setIsFrozen(cryptoBalances.every(balance => balance.isFreeze));

        fetchData();
    }, [cryptoBalances]);

    const handleOpenModal = currencyShortName => {
        const balance = cryptoBalances.find(item => item?.currencyShortName === currencyShortName);
        setSelectedBalance(balance);
        setOpenConfirmModal(true);
    };

    const handleModalClose = () => {
        setOpenConfirmModal(false);
        setOpenChangeModal(false);
    };

    const handleOpenChangeModal = () => {
        setOpenChangeModal(true);
        setOpenConfirmModal(false);
    };

    const fetchData = async () => {
        setBalanceLoading(true);
        try {
            const data = await Promise.all(
                cryptoBalances.map(async (item, index) => {
                    return {
                        key: (index + 1).toString(),
                        currencyType: {
                            icon: item?.currencyIcon,
                            currencyName: `${item?.currencyFullName} (${item?.currencyShortName})`,
                        },
                        usdAmount: `$ ${item?.convertedUSDAmount ? toFixedTrunc(item?.convertedUSDAmount, 2) : 0}`,
                        cryptoBalances: {
                            balance: item?.balanceAmount,
                            currencyShortName: item?.currencyShortName,
                            isFreeze: item?.isFreeze,
                        },
                    };
                }),
            );
            setDataSource(data);
            setBalanceLoading(false);
        } catch (error) {
            setBalanceLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const openBalanceHistory = shortName => {
        const selectedBalance = cryptoBalances.find(
            balance => balance?.currencyShortName === shortName,
        );
        window.open(
            `/accounts/${id}/balance-history/${selectedBalance?.currencyShortName}`,
            '_blank',
        );
    };

    const createColumns = isMobile => {
        const commonColumns = [
            {
                title: 'Currency',
                dataIndex: 'currencyType',
                key: 'currencyType',
                width: isMobile ? '80%' : '50%',
                render: data => (
                    <div className="flex items-center">
                        <img
                            src={`${imageBaseUrl}${data.icon}`}
                            alt="crypto-logo"
                            className="w-[30px] h-[30px] object-cover"
                        />
                        <span className="ml-4">
                            {getCryptoNameBySymbol(data?.currencyName)}
                            {data?.currencyName}
                        </span>
                    </div>
                ),
            },
            {
                title: 'Crypto Balances',
                align: 'center',
                dataIndex: 'cryptoBalances',
                key: 'cryptoBalances',
                width: isMobile ? '10%' : '25%',
                render: data => (
                    <div className="flex items-center">
                        <Button
                            // onClick={() => handleOpenModal(data?.currencyShortName)}
                            className="p-2 h-full w-[148px] flex justify-between disabled:text-gray-300 disabled:bg-inherit"
                            disabled={data?.isFreeze}>
                            <p>{data?.balance ? toFixedTrunc(data?.balance, 6) : 0}</p>
                            <p className="flex justify-between items-center gap-2">
                                {data.currencyShortName}
                                <span className="flex flex-col gap-[1px]">
                                    <CaretUpOutlined className="text-[#A0A0AB] w-2 h-1" />
                                    <CaretDownOutlined className="text-[#A0A0AB] w-2 h-1" />
                                </span>
                            </p>
                        </Button>
                        <Button
                            className="p-2 h-full ml-[8px] hidden lg:block flex-shrink-0"
                            onClick={() =>
                                handleFreezeCurrency(data?.currencyShortName, !data?.isFreeze)
                            }
                            icon={
                                <img
                                    src={`${imageBaseUrl}${data?.isFreeze ? DefrostIcon : Freeze}`}
                                    alt={data?.isFreeze ? 'Unfreeze' : 'Freeze'}
                                    className="w-4 h-4 inline"
                                />
                            }
                        />
                        <Button
                            onClick={() => openBalanceHistory(data?.currencyShortName)}
                            className="h-full ml-2 fiat-balance-freez-btn hidden lg:block"
                            icon={
                                <img
                                    src={`${imageBaseUrl}${BalanceHistory}`}
                                    alt="balanceHistory"
                                    className="w-16px h-16px inline"
                                />
                            }></Button>
                    </div>
                ),
            },
            Table.EXPAND_COLUMN,
        ];

        if (!isMobile) {
            commonColumns.splice(1, 0, {
                title: 'USD',
                dataIndex: 'usdAmount',
                key: 'usdAmount',
                render: data => <p className="hidden md:block">{data}</p> || 0,
                width: '25%',
            });
        }

        return commonColumns;
    };
    const handleResize = () => {
        const isMobile = windowWidth < 1024;
        setColumns(createColumns(isMobile));
    };

    useEffect(() => {
        handleResize();
    }, [windowWidth]);

    const onExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record?.key]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter(key => key !== record?.key));
        }
    };

    const ExpandedRowItem = record => {
        const { label, image, itemText } = record;
        return (
            <div className="flex justify-between">
                <p className="text-[#51525C]">{label}</p>
                <div>
                    <p className="flex items-center">
                        {image && (
                            <img src={`${imageBaseUrl}${image}`} alt="logo" className="w-6 h-6" />
                        )}
                        <span className="ml-4">{itemText}</span>
                    </p>
                </div>
            </div>
        );
    };

    const expandableConfig = {
        expandedRowRender: record => (
            <div className="flex flex-col gap-2 border-[1px] rounded-lg p-3">
                <ExpandedRowItem
                    label="Currency:"
                    image={record?.currencyType?.icon}
                    itemText={record?.currencyType?.currencyName}
                />
                <ExpandedRowItem label="Balances in USD:" itemText={record?.usdAmount} />
                <hr className="my-2" />
                <div className="flex gap-[6px]">
                    <ThemeButton
                        text={record?.cryptoBalances?.isFreeze ? 'Unfreeze' : 'Freeze'}
                        style="mr-3 w-24"
                        autoWidth={true}
                        icon={
                            <img
                                src={`${imageBaseUrl}${record?.cryptoBalances?.isFreeze ? DefrostIcon : Freeze}`}
                                alt={record?.cryptoBalances?.isFreeze ? 'Unfreeze' : 'Freeze'}
                                className="w-4 h-4 inline"
                            />
                        }
                        action={() =>
                            handleFreezeCurrency(
                                record?.cryptoBalances?.currencyShortName,
                                !record?.cryptoBalances?.isFreeze,
                            )
                        }
                    />
                    <ThemeButton
                        text="Balance History"
                        style="w-34"
                        autoWidth={true}
                        icon={
                            <img
                                src={`${imageBaseUrl}${BalanceHistory}`}
                                alt="balanceHistory"
                                className="w-4 h-4"
                            />
                        }
                        action={() => openBalanceHistory(record?.cryptoBalances?.currencyShortName)}
                    />
                </div>
            </div>
        ),
        onExpand,
        expandedRowKeys,
        expandIcon: ({ onExpand, expanded, record }) => (
            <span
                onClick={e => {
                    onExpand(record);
                    e.stopPropagation();
                }}>
                {expanded ? (
                    <UpOutlined className="block lg:hidden" />
                ) : (
                    <DownOutlined className="block lg:hidden" />
                )}
            </span>
        ),
    };

    const handleFreezeCurrency = async (currency, isFreeze) => {
        try {
            setBalanceLoading(true);
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                currency,
                isFreeze,
                email,
                currencyType: 'crypto',
            });
            if (response.success) {
                await getCryptoBalances(
                    dispatch,
                    clientData?.clientId,
                    paymentProvider,
                    email,
                    setBalanceLoading,
                );
            } else {
                message.error(response.error);
            }
            setFreezeModalOpen(false);
            setBalanceLoading(false);
        } catch (error) {
            setFreezeModalOpen(false);
            setBalanceLoading(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleFreezeAllCurrencies = async () => {
        try {
            setLoading(true);
            const response = await apiRequest('/freeze-client-balances', 'POST', {
                clientId: clientData.clientId,
                isFreeze: !isFrozen,
                email,
                freezeAll: true,
                currencyType: 'crypto',
            });
            if (response.success) {
                await getCryptoBalances(
                    dispatch,
                    clientData?.clientId,
                    paymentProvider,
                    email,
                    setBalanceLoading,
                );
            } else {
                message.error(response.error);
            }
            setFreezeModalOpen(false);
            setLoading(false);
        } catch (error) {
            setFreezeModalOpen(false);
            setLoading(false);
            console.error('Error while freezing currency:', error);
        }
    };

    const handleOpenFreezeModal = () => {
        setFreezeModalOpen(true);
    };

    return (
        <>
            <div className="lg:pt-8 bg-white">
                <div className="flex items-center justify-between pt-4">
                    <h1 className="text-lg font-semibold">The Accounts displayed balances</h1>
                    <ThemeButton
                        action={handleOpenFreezeModal}
                        text={isFrozen ? 'Unfreeze All' : 'Freeze All'}
                        style="mr-3"
                        autoWidth={true}
                        icon={
                            <img
                                src={`${imageBaseUrl}${isFrozen ? DefrostIcon : Freeze}`}
                                alt={isFrozen ? 'Unfreeze' : 'Freeze'}
                                text={isFrozen ? 'Unfreeze' : 'Freeze'}
                                className="w-4 h-4"
                            />
                        }
                    />
                </div>
                {balanceLoading ? (
                    <div className="flex flex-col gap-5">
                        <Loading />
                    </div>
                ) : (
                    <div className="mt-6">
                        <Table
                            className="crypto-balance"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                            expandable={expandableConfig}
                        />
                    </div>
                )}
            </div>
            {openConfirmModal && (
                <ConfirmBalanceChangeModal
                    open={openConfirmModal}
                    title="Are you sure?"
                    balance={selectedBalance}
                    handleClose={handleModalClose}
                    handleChange={handleOpenChangeModal}
                />
            )}
            {openChangeModal && (
                <ChangeBalanceForm
                    form={form}
                    title="Edit Balance"
                    balance={selectedBalance}
                    handleClose={handleModalClose}
                    footerText="Save"
                    open={openChangeModal}
                />
            )}
            {freezeModalOpen && (
                <FreezeConfirmationModal
                    open={freezeModalOpen}
                    isFrozen={isFrozen}
                    handleClose={() => setFreezeModalOpen(false)}
                    freezeAllCurrencies={handleFreezeAllCurrencies}
                    loading={loading}
                />
            )}
        </>
    );
};

export default CryptoBalancesTab;
