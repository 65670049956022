import { Button, Drawer, message } from 'antd';
import { DefrostIcon, Freeze, PencilDots, WalletIcon } from '../../utils/Images';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CopyButton } from '../../utils/common';
import apiRequest from '../../utils/api';
import ThemeButton from '../common/ThemeButton';
import { setCryptoBalancesData } from '../../redux/actions/accountActions';
import { CloseOutlined } from '@ant-design/icons';

export default function CryptoWalletItem({ wallet, handleFreezeCurrency }) {
    const dispatch = useDispatch();
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const clientData = useSelector(state => state.account.clientData);
    const [toggleAddress, setToggleAddress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const email = localStorage.getItem('email');

    const fetchClientWallets = async () => {
        const requestBody = {
            clientId: clientData.clientId,
            email,
        };
        try {
            const response = await apiRequest('/get-crypto-balance', 'POST', requestBody);

            if (response.success) {
                if (response.data) {
                    dispatch(setCryptoBalancesData(response.data));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const sliceWalletAddress = address => {
        return address.slice(0, 8) + '...' + address.slice(-8);
    };
    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    const generateWalletAddress = async () => {
        setIsLoading(true);
        const requestBody = {
            clientId: clientData.clientId,
            email,
            cryptoId: wallet.currencyShortName,
            blockchain: wallet.blockchain,
            accountNumber: clientData.accountNumber,
        };
        try {
            const isWalletGenerated = await apiRequest(
                '/update-wallet-address',
                'POST',
                requestBody,
            );
            if (isWalletGenerated.success) {
                await fetchClientWallets();
            } else {
                message.error('Failed to generate wallet address');
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    const toggleWalletAddress = () => {
        setToggleAddress(!toggleAddress);
    };

    const openDrawerHandler = () => {
        setOpenDrawer(true);
    };

    return (
        <>
            <div className="crypto-wallet-item">
                <div className="flex gap-[16px] items-center">
                    <img
                        className="w-[30px] h-[30px] object-cover"
                        src={`${imageBaseUrl}${wallet.currencyIcon}`}
                        alt="crypto-icon"
                    />
                    <p className="text-[14px]">{wallet.currencyShortName}</p>
                </div>
                {toggleAddress ? (
                    <div className="flex items-center gap-[10px]">
                        <div className="crypto-wallet-address">{`${sliceWalletAddress(wallet.walletAddress.key)} >`}</div>
                        <CopyButton msg={wallet.walletAddress.key} />
                        <Button
                            className="border-0 close-icon"
                            icon={<CloseOutlined />}
                            onClick={toggleWalletAddress}
                        />
                    </div>
                ) : (
                    <div>
                        {wallet.walletAddress ? (
                            <div>
                                <ThemeButton
                                    style="mr-[4px] !w-[36px] !h-[36px]"
                                    autoWidth={true}
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${wallet?.isFreeze ? DefrostIcon : Freeze}`}
                                            alt={wallet?.isFreeze ? 'Unfreeze' : 'Freeze'}
                                            className="w-4 h-4 inline"
                                        />
                                    }
                                    action={() =>
                                        handleFreezeCurrency(
                                            wallet?.currencyShortName,
                                            !wallet?.isFreeze,
                                        )
                                    }
                                />
                                <ThemeButton
                                    action={toggleWalletAddress}
                                    style="!w-[36px] !h-[36px] disabled:text-gray-300 disabled:bg-gray-300"
                                    autoWidth={true}
                                    icon={
                                        <img
                                            src={`${imageBaseUrl}${WalletIcon}`}
                                            alt="freeze"
                                            className="w-4 h-4"
                                        />
                                    }
                                    disabled={wallet?.isFreeze}
                                />
                            </div>
                        ) : (
                            <Button
                                loading={isLoading}
                                type="primary"
                                className="px-[12px] py-[8px] mr-3 rounded-[8px] text-[14px] flex flex-row-reverse"
                                data-e2e="create-new-transaction"
                                onClick={generateWalletAddress}>
                                {isLoading ? 'Processing' : 'Generate wallet address'}
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <div className="crypto-wallet-item-mobile">
                <div className="flex gap-[16px] items-center">
                    <img
                        className="w-[30px] h-[30px] object-cover"
                        src={`${imageBaseUrl}${wallet.currencyIcon}`}
                        alt="crypto-icon"
                    />
                    <p className="text-[14px]">{wallet.currencyShortName}</p>
                </div>
                <div onClick={openDrawerHandler}>
                    <img src={`${imageBaseUrl}${PencilDots}`} alt="" className="w-4 h-4" />
                </div>
                <Drawer
                    title={
                        <div className="flex gap-[16px] items-center">
                            <img
                                className="w-[44px] h-[44px] object-cover"
                                src={`${imageBaseUrl}${wallet.currencyIcon}`}
                                alt="crypto-icon"
                            />
                            <p className="text-[14px]">{wallet.currencyShortName}</p>
                        </div>
                    }
                    placement="bottom"
                    closable={true}
                    onClose={closeDrawer}
                    height="auto"
                    className="crypto-wallet-mobile-drawer"
                    open={openDrawer}
                    key="bottom">
                    <div>
                        {wallet.walletAddress !== '' ? (
                            <div>
                                <div
                                    className="crypto-wallet-mobile-drawer-container flex flex-col gap-[16px]"
                                    onClick={() =>
                                        handleFreezeCurrency(
                                            wallet?.currencyShortName,
                                            !wallet?.isFreeze,
                                        )
                                    }>
                                    <div className="crypto-wallet-freeze-icon-container">
                                        <img
                                            src={`${imageBaseUrl}${wallet?.isFreeze ? DefrostIcon : Freeze}`}
                                            alt={wallet?.isFreeze ? 'Unfreeze' : 'Freeze'}
                                            className="w-4 h-4 inline"
                                        />
                                    </div>
                                    <p className="text-[16px]">
                                        {wallet?.isFreeze ? 'Unfreeze' : 'Freeze'}
                                    </p>
                                </div>
                                <div className="crypto-wallet-mobile-drawer-container mt-[12px]">
                                    <p className="text-[#51525C]">Generated wallet address:</p>
                                    <div className="flex justify-between mt-[8px]">
                                        <p className="text-[16px] text-[#1570EF]">
                                            {sliceWalletAddress(wallet.walletAddress.key)}
                                        </p>
                                        <CopyButton msg={wallet.walletAddress.key} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="crypto-wallet-mobile-drawer-container">
                                <p className="text-[14px] text-[#51525C]">Wallet address:</p>
                                <p className="text-[16px] text-[#D92D20] mt-[6px]">Not generated</p>
                                <Button
                                    loading={isLoading}
                                    type="primary"
                                    className="wallet-item-btn-mobile"
                                    data-e2e="create-new-transaction"
                                    onClick={generateWalletAddress}>
                                    {isLoading ? 'Processing' : 'Generate'}
                                </Button>
                            </div>
                        )}
                    </div>
                </Drawer>
            </div>
        </>
    );
}
