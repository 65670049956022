import React, { useEffect, useState } from 'react';
import { Button, Input, message, Table } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import CommonModal from '../modals/CommonModal';
import { useParams } from 'react-router-dom';
import apiRequest from '../../utils/api';
import ThemeButton from '../common/ThemeButton';
import { useSelector } from 'react-redux';
import { addAuditTrail, useCommonFunctions, useWindowWidth } from '../../utils/common';
import { Edit } from '../../utils/Images';
import { fiatTransactionsType } from '../../utils/constant';

const FeeTab = () => {
    const [errorMsg, setErrorMsg] = useState('');
    const [data, setData] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Deposit');
    const [fees, setFees] = useState({});
    const [editAllFee, setEditAllFee] = useState('');
    const [editFeeModal, setEditFeeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const isFX = selectedTab === 'FX';
    const [toCurrencyIndex, setToCurrencyIndex] = useState(0);
    const [refreshKey, setRefreshKey] = useState(0);

    const params = useParams();
    const email = localStorage.getItem('email');
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    const imageBaseUrl = useSelector(state => state.config.image_base_url);
    const { clientData, balancesData } = useSelector(state => state.account);
    const toCurrencies = balancesData.map(fee => fee?.currencyShortName) || [];
    const { getClientFeeSetting } = useCommonFunctions();

    const fetchClientsFee = async () => {
        const body = {
            email: email,
            clientId: params.id,
            transactionType: selectedTab,
            currencyType: 'fiat',
        };
        setLoading(true);
        try {
            const response = await getClientFeeSetting(body);

            if (response.success && response.data) {
                setData(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientsFee();

        // When selectedTab change empty the fees obj
        setFees({});
    }, [params.id, selectedTab, refreshKey]);

    const handleCloseModal = () => {
        setEditAllFee('');
        setEditFeeModal(false);
        setErrorMsg('');
    };

    const handleEditAllFee = value => {
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue && !handleInputValidation(numericValue)) {
            return;
        }
        setEditAllFee(numericValue);
    };

    const handleApiCall = async ({ currency, percentFee, toCurrency, updateAllFee }) => {
        const payload = {
            clientId: clientData?.clientId,
            email,
            transactionType: selectedTab,
            currency: currency,
            currencyType: 'fiat',
            percentFee: percentFee || editAllFee,
            ...(currency && { currency }),
            ...(toCurrency && { toCurrency }),
            ...(updateAllFee && { updateAllFee }),
        };

        const findOldFee = () => {
            if (updateAllFee) return undefined;

            if (selectedTab === 'FX') {
                return data
                    .find(e => e.transactionType === 'FX' && e.currency === currency)
                    ?.fees.find(fee => fee.toCurrency === toCurrency)?.percentFee;
            }

            return data.find(e => e.transactionType === selectedTab && e.currency === currency)
                ?.percentFee;
        };

        const createAuditTrailData = oldFee => {
            const baseData = {
                transactionType: selectedTab,
            };

            const updatedFXData =
                selectedTab === 'FX'
                    ? data.map(feeData => {
                          const temp = feeData.currency;
                          const newItem = JSON.parse(JSON.stringify(feeData));
                          newItem.fromCurrency = temp;
                          delete newItem.currency;
                          return newItem;
                      })
                    : data;

            const originalData = updateAllFee
                ? { ...updatedFXData }
                : {
                      ...baseData,
                      percentFee: oldFee || 0,
                      ...(selectedTab === 'FX'
                          ? { fromCurrency: currency, toCurrency }
                          : { currency }),
                  };

            const newData = updateAllFee
                ? {
                      ...baseData,
                      percentFee: editAllFee || 0,
                      ...(selectedTab === 'FX'
                          ? { fromCurrency: 'All', toCurrency: 'All' }
                          : { currency: 'All' }),
                  }
                : {
                      ...baseData,
                      percentFee: percentFee || 0,
                      ...(selectedTab === 'FX'
                          ? { fromCurrency: currency, toCurrency }
                          : { currency }),
                  };

            return { originalData, newData };
        };

        setLoading(true);
        try {
            const response = await apiRequest('/save-client-fee-setting', 'POST', payload);
            if (response.success) {
                setRefreshKey(refreshKey + 1);
                const oldFee = findOldFee();

                const { originalData, newData } = createAuditTrailData(oldFee);

                const auditTrailBody = {
                    userId: email,
                    pageUrl: window.location.href,
                    clientId: clientData?.clientId,
                    eventType: 'Update Fee',
                    ipAddress: localStorage.getItem('ip') || '',
                    userAgent: window.navigator.userAgent,
                    originalData,
                    newData,
                };

                addAuditTrail(auditTrailBody);
            } else {
                setErrorMsg(response.error);
                message.error(response.error);
            }
            updateAllFee && handleCloseModal();
            setLoading(false);
        } catch (error) {
            console.error(error);
            message.error(error);
            setLoading(false);
        }
    };

    const handleInputValidation = value => {
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue === '') return true;
        const isValidNumber = /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(numericValue);
        return isValidNumber && parseFloat(numericValue) >= 0;
    };

    const handleFXInputChange = (currency, toCurrency, value) => {
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue && !handleInputValidation(numericValue)) {
            return;
        }

        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                [toCurrency]: {
                    ...prev[currency]?.[toCurrency],
                    value: numericValue.replace('%', ''),
                    focused: true,
                    valueChanged: true,
                },
            },
        }));
    };

    const handleFXFocus = (fromCurrency, toCurrency) => {
        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                [toCurrency]: {
                    ...prev[fromCurrency]?.[toCurrency],
                    focused: true,
                    value: prev[fromCurrency]?.[toCurrency]?.value || '',
                    valueChanged: false,
                },
            },
        }));
    };

    const handleFXBlur = (fromCurrency, toCurrency) => {
        const currentFee = fees[fromCurrency]?.[toCurrency];
        const currentValue = currentFee?.value;
        const valueChanged = currentFee?.valueChanged;

        // Make API call on blur
        if (currentValue !== undefined && valueChanged) {
            handleApiCall({
                currency: fromCurrency,
                percentFee: currentValue,
                toCurrency,
            });
        }

        setFees(prev => ({
            ...prev,
            [fromCurrency]: {
                ...prev[fromCurrency],
                [toCurrency]: {
                    ...prev[fromCurrency]?.[toCurrency],
                    focused: false,
                    value: currentValue ? `${currentValue}` : prev[fromCurrency]?.[toCurrency]?.fee,
                    valueChanged: false,
                },
            },
        }));
    };

    const handleInputChange = (value, currency) => {
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue && !handleInputValidation(numericValue)) {
            return;
        }

        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                value: numericValue.replace('%', ''),
                focused: true,
                valueChanged: true,
            },
        }));
    };

    const handleFocus = currency => {
        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                focused: true,
                value: prev[currency]?.value || prev[currency]?.fee || '',
                valueChanged: false,
            },
        }));
    };

    const handleBlur = currency => {
        const currentFee = fees[currency];
        const currentValue = currentFee?.value;
        const valueChanged = currentFee?.valueChanged;

        // Make API call on blur
        if (currentValue !== undefined && valueChanged) {
            handleApiCall({
                currency,
                percentFee: currentValue,
            });
        }

        setFees(prev => ({
            ...prev,
            [currency]: {
                ...prev[currency],
                focused: false,
                value: currentValue ? `${currentValue}` : prev[currency]?.fee,
                valueChanged: false,
            },
        }));
    };

    const getCurrencyColumn = row => (
        <div className="flex gap-4 items-center text-wrap">
            <img
                className="w-[30px] h-[30px] rounded-full object-cover"
                src={`${imageBaseUrl}${row?.currencyIcon}`}
                alt="Avatar"
            />
            <p className=" text-[#18181B] text-sm font-normal">
                {row?.currencyFullName}
                {` (${row?.currency})`}
            </p>
        </div>
    );

    const getColumns = () => {
        const columns = [
            {
                title: 'Currency',
                key: 'currency',
                width: isMobile ? '50%' : '85%',
                render: getCurrencyColumn,
            },
            {
                title: "Fee's",
                key: 'percentFee',
                width: isMobile ? '50%' : 200,
                render: row => (
                    <div className="flex items-center">
                        <Input
                            onChange={e => handleInputChange(e.target.value, row.currency)}
                            onFocus={() => handleFocus(row.currency)}
                            onBlur={() => handleBlur(row.currency)}
                            className="p-2 h-full w-[148px] float-end"
                            value={
                                fees[row?.currency]?.focused
                                    ? fees[row?.currency]?.value
                                    : `${fees[row?.currency]?.value || row?.percentFee || 0}%`
                            }
                            type="text"
                            inputMode="decimal"
                        />
                    </div>
                ),
                align: 'left',
            },
        ];

        return columns;
    };

    const getFXInputColumn = (record, toCurrency) => {
        const feeObject = record.fees?.find(fee => fee.toCurrency === toCurrency);
        const percentFee = feeObject?.percentFee || 0;

        return (
            <Input
                value={
                    fees[record?.currency]?.[toCurrency]?.focused
                        ? fees[record?.currency]?.[toCurrency]?.value || ''
                        : `${fees[record?.currency]?.[toCurrency]?.value || percentFee}%`
                }
                disabled={record?.currency === toCurrency}
                onChange={e => handleFXInputChange(record?.currency, toCurrency, e.target.value)}
                onFocus={() => handleFXFocus(record?.currency, toCurrency)}
                onBlur={() => handleFXBlur(record?.currency, toCurrency)}
                className="p-[10px] w-full sm:w-[148px]"
                type="text"
                inputMode="decimal"
            />
        );
    };

    const getFXColumns = data => {
        if (isMobile) {
            const currentToCurrency = toCurrencies[toCurrencyIndex];
            return [
                {
                    title: 'Currency',
                    dataIndex: 'currency',
                    key: 'currency',
                    fixed: 'left',
                    width: '50%',
                    render: (_, record) => getCurrencyColumn(record),
                },
                {
                    title: (
                        <div className="flex justify-center items-center gap-5">
                            <ThemeButton
                                type="text"
                                icon={<LeftOutlined />}
                                action={handlePrevCurrency}
                                className="h-5 w-5"
                            />
                            <span className="text-gray-600 text-ellipsis font-medium">
                                {currentToCurrency}
                            </span>
                            <ThemeButton
                                type="text"
                                icon={<RightOutlined />}
                                className="h-5 w-5"
                                action={handleNextCurrency}
                            />
                        </div>
                    ),
                    key: 'combined',
                    width: '50%',
                    render: record => (
                        <div className="flex w-full">
                            {getFXInputColumn(record, currentToCurrency)}
                        </div>
                    ),
                },
            ];
        }

        return [
            {
                title: 'Currency',
                dataIndex: 'currency',
                key: 'currency',
                fixed: 'left',
                width: isMobile ? '50%' : 200,
                render: (_, record) => getCurrencyColumn(record),
            },

            ...data.map(toCurrency => ({
                title: toCurrency?.currency,
                key: toCurrency?.currency,
                dataIndex: toCurrency?.currency,
                width: 200,
                render: (value, record) => {
                    const feeObject = record.fees?.find(
                        fee => fee.toCurrency === toCurrency.currency,
                    );
                    const percentFee = feeObject?.percentFee || 0;

                    return (
                        <Input
                            value={
                                fees[record?.currency]?.[toCurrency?.currency]?.focused
                                    ? fees[record?.currency]?.[toCurrency?.currency]?.value || ''
                                    : `${fees[record?.currency]?.[toCurrency?.currency]?.value || percentFee}%`
                            }
                            disabled={record?.currency === toCurrency?.currency}
                            onChange={e =>
                                handleFXInputChange(
                                    record?.currency,
                                    toCurrency.currency,
                                    e.target.value,
                                )
                            }
                            onFocus={() => handleFXFocus(record?.currency, toCurrency.currency)}
                            onBlur={() => handleFXBlur(record?.currency, toCurrency.currency)}
                            className="p-[10px] w-[148px]"
                            type="text"
                            inputMode="decimal"
                        />
                    );
                },
            })),
        ];
    };

    const handlePrevCurrency = () => {
        setToCurrencyIndex(prev => (prev === 0 ? toCurrencies.length - 1 : prev - 1));
    };

    const handleNextCurrency = () => {
        setToCurrencyIndex(prev => (prev + 1) % toCurrencies.length);
    };

    return (
        <div className="lg:pt-8 bg-white flex flex-col gap-6">
            <div className="flex items-center justify-between pt-4 lg:p-0 px-6 lg:px-0">
                <h1 className="lg:text-lg text-base font-semibold">Fee&apos;s</h1>
            </div>
            <div className="flex items-center z-50 cursor-pointer border-b px-2 w-full overflow-x-auto">
                {fiatTransactionsType
                    ?.filter(type => type.currencyType === 'fiat' && type.value !== 'Merchant')
                    ?.map(item => (
                        <div
                            key={item.value}
                            className={`flex justify-center items-center p-2 text-[15px] font-medium text-[#70707B] ${
                                selectedTab.includes(item.value)
                                    ? 'border-b-2 border-[#18181B] !text-[#18181B]'
                                    : ''
                            }`}
                            onClick={() => setSelectedTab(item.value)}>
                            {item.label}
                        </div>
                    ))}
            </div>

            <div className="flex items-center justify-between">
                <div className="ml-auto">
                    <Button
                        icon={
                            <img
                                src={`${imageBaseUrl}${Edit}`}
                                alt="edit"
                                className="lg:w-4 w-6 h-6 lg:h-4"
                            />
                        }
                        onClick={() => setEditFeeModal(true)}
                        className="lg:border border-0 border-[#D1D1D6] font-medium text-sm">
                        <span className="!hidden lg:!block">Edit all</span>
                    </Button>
                </div>
            </div>

            <Table
                dataSource={data}
                columns={isFX ? getFXColumns(data) : getColumns(data)}
                rowKey={record => record.currency}
                pagination={false}
                scroll={{ y: 470 }}
                className="lg:h-auto overflow-auto hide-scrollbar"
                loading={loading}
            />
            <CommonModal
                title="Edit All Fee’s"
                open={editFeeModal}
                handleClose={handleCloseModal}
                className={'common-mobile-view'}
                footerText={'Apply'}
                disabled={!editAllFee || loading}
                onFinish={() => editAllFee && handleApiCall({ updateAllFee: true })}
                centered>
                <div className="flex justify-between items-center gap-10 mt-6 p-1">
                    <h1 className="text-base font-normal text-[#26272B]">Amount:</h1>
                    <Input
                        value={editAllFee}
                        onChange={e => handleEditAllFee(e.target.value)}
                        placeholder="Enter percentage fee"
                        className="rounded-3xl border-[#E4E4E7] h-[50px] max-w-[320px] bg-[#F4F4F5]"
                        type="text"
                        inputMode="decimal"
                    />
                </div>
                {errorMsg && <div className="text-red-500 mt-3">{errorMsg}</div>}
            </CommonModal>
        </div>
    );
};

export default FeeTab;
